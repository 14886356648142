.flex-view-component {
  display: grid;
  grid-template-columns: minmax(var(--flex-aside-maxwidth), 1fr) max-content;
  overflow-x: hidden;
}

@media (min-width: 640px) {
  .flex-view-component {
    overflow-x: hidden;
  }
}

.flex-gateway-layout .navigation {
  background: #f6f3ea;
}

.flex-gateway-layout.fullpage {
  display: grid;
  grid-template-columns: 100% 0%;
  height: 100vh;
}

.flex-gateway-layout.fullpage .navigation {
  display: grid;
  min-width: 100%;
}

.flex-gateway-layout.fullpage .component {
  display: grid;
  max-width: 0%;
  overflow: hidden;
}

.flex-gateway-layout.aside .navigation {
  padding: var(--flex-aside-padding);
}

.flex-gateway-layout.aside {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}

.flex-gateway-layout.aside .component.gateway-ssr-404,
.flex-gateway-layout.aside .component.gateway-ssr-500 {
  position: relative;
}

.flex-gateway-layout.aside .component .customError {
  position: absolute;
  width: 100%;
  top: 33vh;
  text-align: center;
  color: white;
}

.flex-gateway-layout.aside .component .customError.above-logo {
  top: calc(50% - 160px);
}

.flex-gateway-layout.aside .component .customError.below-logo {
  top: calc(50% + 150px);
}

[class*='animate'] {
  -webkit-animation: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
  animation: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
}

@keyframes to-aside-width {
  from {
    max-width: 100vw;
  }
  to {
    max-width: var(--flex-aside-maxwidth);
  }
}

.animate-to-aside-width {
  -webkit-animation-name: to-aside-width;
  animation-name: to-aside-width;
}

@media (min-width: 900px) {
  .flex-gateway-layout.aside {
    display: grid;
    /* grid-template-columns: 25% 75%; */
    grid-template-columns: minmax(var(--flex-aside-maxwidth), 1fr) max-content;
    overflow-x: hidden;
  }

  .flex-gateway-layout.aside .navigation {
    display: grid;
    z-index: 10;
    position: fixed;
    max-width: var(--flex-aside-maxwidth);
    height: 100%;
    max-height: 100vh;
    overflow-y: auto;
    -webkit-animation: to-aside-width 1s cubic-bezier(0.654, 0.045, 0.355, 1);
    animation: to-aside-width 1s cubic-bezier(0.654, 0.045, 0.355, 1);
  }

  .flex-gateway-layout.aside .navigation .flex-gateway-navigation.aside {
    max-width: calc(var(--flex-aside-maxwidth) - (2 * var(--flex-aside-padding)));
  }

  .flex-gateway-layout.aside .component,
  .flex-gateway-layout.aside .component.gateway-ssr-404,
  .flex-gateway-layout.aside .component.gateway-ssr-500 {
    display: grid;
    z-index: 9;
    position: relative;
    width: calc(100% - var(--flex-aside-maxwidth));
    left: var(--flex-aside-maxwidth);
  }

  .flex-gateway-layout.aside .component .customError {
    position: absolute;
    width: 500px;
    top: 33vh;
    left: calc(50% - 250px);
    text-align: center;
    color: white;
  }

  .flex-gateway-layout.aside .component .customError.above-logo {
    top: calc(50% - 140px);
  }

  .flex-gateway-layout.aside .component .customError.below-logo {
    top: calc(50% + 128px);
  }
}

.flex-gateway-layout .component .logoHolder {
  position: relative;
  height: 100vh;
  background-color: var(--flex-gateway-highlight, #f9423a);
}

.flex-gateway-logo.fullpage .component svg#flexiness_logo_1 {
  display: none
}

.flex-gateway-layout.aside .component svg#flexiness_logo_1 {
  position: absolute;
  width: 100%;
  left: 0;
  top: calc(50vh - (274.5px / 2));
}

@media (min-width: 900px) {
  .flex-gateway-layout.aside .component svg#flexiness_logo_1 {
    position: absolute;
    width: 500px;
    left: calc(50% - 250px);
    top: calc(50vh - (274.5px / 2));
  }
}

.flex-gateway-layout.aside .component svg#flexiness_logo_2 {
  position: absolute;
  width: 414px;
  left: calc(50% - (414px / 2));
  top: calc(50vh - (220px / 2));
}

/* 
@media (min-width: 900px) {
  .flex-gateway-layout.aside .component svg#flexiness_logo_2 {
    position: absolute;
    width: 414px;
    left: calc(50% - (414px / 2));
    top: calc(50vh - (220px / 2));
  }
}
*/

@media screen and (orientation: portrait) {
  .flex-gateway-layout.aside.is-flex-mobile .component svg#flexiness_logo_2 {
    position: relative;
    width: 90vw;
    left: calc(50vw - 90vw / 2);
    top: calc(50vh - (220px / 2));
  }
}

@media screen and (orientation: landscape) {
  .flex-gateway-layout.aside.is-flex-mobile .component svg#flexiness_logo_2 {
    position: relative;
    width: 50vw;
    left: calc(50vw - 50vw / 2);
    top: calc(50vh - (220px / 2));
  }
}

/*/////////////////////////////////////////////////////////////////////*/

.flex-gateway-layout.top {
  display: grid;
  overflow-x: hidden;
  position: relative;
}

.flex-gateway-layout.top .navigation {
  width: 100%;
  height: 5rem;
  position: fixed;
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: max-content auto max-content;
  grid-template-areas: "logo menu toggler";
  justify-items: stretch;
  align-items: center;
  column-gap: 20px;
  background-color: rgba(250, 250, 250, 0.25);
  color: var(--flex-gateway-text: #32325d, #109db9) !important;
  z-index: 3;
}

.flex-gateway-layout.top .navigation.headroom {
  will-change: transform;
  transition: transform 200ms linear;
}

.flex-gateway-layout.top .navigation.headroom--unpinned {
  transform: translateY(-100%);
  background-color: rgba(250, 250, 250, 0.5);
}

.flex-gateway-layout.top .navigation.headroom--pinned {
  transform: translateY(0%);
  background-color: rgba(250, 250, 250, 1);
  transition: background-color 500ms linear;
}

.flex-gateway-layout.top .navigation.headroom--pinned.headroom--top {
  background-color: rgba(250, 250, 250, 0.25);
  transition: background-color 500ms linear;
}

.flex-gateway-layout.top .navigation .menus {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.flex-gateway-layout.top .navigation .toggler {
  display: flex;
  flex-direction: row;
  justify-content: center;
  min-width: 50px;
  right: 1rem;
  margin-top: -0.5rem;
  font-size: 3rem;
  position: relative;
  color: rgba(0, 0, 0, 1);
}

/* .flex-gateway-layout.top .navigation .toggler:hover {
  color: rgba(0, 0, 0, 1);
  mix-blend-mode: normal;
} */

.flex-gateway-layout.top .navigation .flex-gateway-navigation.top header {
  padding: 1em;
  background: transparent;
}

/*/////////////////////////////////////////////////////////////////////*/

.flex-gateway-layout.aside.flex-overlay {
  height: 100%;
  overflow: hidden;
}

/*
.flex-gateway-layout.aside.flex-overlay .navigation {
  height: 0;
  width: 0;
  overflow: hidden;
  padding: 0;
}
*/
