/* //////////////////////////////////////////////////////////////////////////////////////// */
/* ///////////////////////////////////////// GLOBAL /////////////////////////////////////// */
/* //////////////////////////////////////////////////////////////////////////////////////// */

:root {
	--flex-aside-maxwidth: 360px;
	--flex-aside-padding: 2em;
}

/* https://github.com/kosbarts/Commissioner */
/* Set up Commissioner for old browsers, only regular + bold */
@font-face {
  font-family: Commissioner;
  src: url('../../../public/fonts/commissioner-v1.0/static/ttfs/Commissioner-Regular.ttf');
  font-weight: normal;
}

@font-face {
  font-family: Commissioner;
  src: url('../../../public/fonts/commissioner-v1.0/static/ttfs/Commissioner-Bold.ttf');
  font-weight: bold;
}

/* Set up Commissioner for modern browsers, all weights */
@supports (font-variation-settings: normal) {
  @font-face {
    font-family: 'Commissioner';
    src:  url('../../../public/fonts/commissioner-v1.0/variable/Commissioner_VF_1.001.ttf') format('truetype'),
          url('../../../public/fonts/commissioner-v1.0/variable/Commissioner_VF_1.001.ttf') format('truetype supports variations'),
          url('../../../public/fonts/commissioner-v1.0/variable/Commissioner_VF_1.001.ttf') format('truetype-variations');
    font-weight: 100 1000;
    font-stretch: 25% 151%;
  }
}

html body .flex-gateway-layout .navigation {
  padding: 0;
  margin: 0;
  color: var(--flex-gateway-text, #32325d);
  background-color: var(--flex-gateway-bg, #f6f3ea);
  font-size: 16px;
  line-height: 1.5;
  font-weight: 400;
  font-family: var(--flex-font-family, Commissioner, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji");
}

html body .flex-gateway-layout .navigation .flex-gateway-nav-menu .flex-gateway-nav-menu-item:empty {
  height: 0;
  margin-top: 0;
}

html body .flex-gateway-layout .navigation .flex-gateway-nav-menu {
  margin-top: -1rem;
}

html body .flex-gateway-layout .navigation .flex-gateway-nav-menu .flex-gateway-nav-menu-item:not(:empty) {
  margin-top: 1rem;
}


html body .flex-gateway-layout.is-flex-mobile .navigation .flex-gateway-nav-menu.open .flex-gateway-nav-menu-item:not(:empty):has(.flex-gateway-navigation) header {
  min-height: 100%;
}

html body .flex-gateway-layout.is-flex-mobile .navigation .flex-gateway-nav-menu.open .flex-gateway-navigation.aside header .header-wrapper {
  margin: 0 auto;
}


html body .flex-gateway-layout .navigation .flex-gateway-panel.aside,
html body .flex-gateway-layout .navigation .flex-gateway-navigatio.aside {
  width: 100%;
}

html body .flex-gateway-layout .navigation .navbar-toggler-icon {
	display: inline-block;
  width: 2.5em;
  height: 2.5em;
	vertical-align: middle;
	content: "";
	background: no-repeat center center;
	background-size: cover;
}

/*
html body .flex-gateway-layout .navigation .navbar-toggler-icon {
	background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='var(--flex-gateway-stroke-grey, rgba(0, 0, 0, 0.5))' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
*/

html body .flex-gateway-layout .navigation .flex-gateway-nav-menu .navbar-toggler-icon.stroke-grey,
html body .flex-gateway-layout .navigation .flex-gateway-nav-menu.closed .navbar-toggler-icon.stroke-grey {
	background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(69, 69, 69, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

html body .flex-gateway-layout .navigation .flex-gateway-nav-menu .navbar-toggler-icon.stroke-grey-invert,
html body .flex-gateway-layout .navigation .flex-gateway-nav-menu.closed .navbar-toggler-icon.stroke-grey-invert {
	background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(250, 250, 250, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

html body .flex-gateway-layout .navigation .flex-gateway-nav-menu.open .flex-gateway-nav-collapse .flex-gateway-nav-toggler-close {
  width: 2.5em;
  height: 2.5em;
  position: relative;
  cursor: pointer;
  display: inline-block;
  padding: 0;
}
html body .flex-gateway-layout .navigation .flex-gateway-nav-menu.open .flex-gateway-nav-collapse .flex-gateway-nav-toggler-close span {
  display: block;
  position: absolute;
  width: 100%;
  height: 2px;
  border-radius: 2px;
  opacity: 1;
  background: var(--flex-gateway-stroke-grey, #283448);
}
html body .flex-gateway-layout .navigation .flex-gateway-nav-menu.open .flex-gateway-nav-collapse .flex-gateway-nav-toggler-close :nth-child(1) {
  transform: rotate(135deg);
}
html body .flex-gateway-layout .navigation .flex-gateway-nav-menu.open .flex-gateway-nav-collapse .flex-gateway-nav-toggler-close :nth-child(2) {
  transform: rotate(-135deg);
}

html body .flex-gateway-layout .navigation .logoNavMob {
  isolation: isolate;
  min-width: 125px;
}

html body .flex-gateway-layout .navigation .logoNavMob .logoHolder,
html body .flex-gateway-layout .navigation .logoNavMob .logoBadge,
html body .flex-gateway-layout .navigation .logoNavMob .logoHolderMatte1,
html body .flex-gateway-layout .navigation .logoNavMob .logoHolderMatte2 {
  width: 125px;
  height: 54px;
}

html body .flex-gateway-layout .navigation .logoNavMob .logoHolder {
  position: relative;
  background-color: var(--flex-gateway-bg, #f6f3ea);
  border: 4px solid white;
}

html body .flex-gateway-layout .navigation .logoNavMob .logoBadge {
  position: relative;
  animation-delay: 500ms;
  -webkit-animation: to-full-background 0.75s cubic-bezier(0.654, 0.045, 0.355, 1);
  animation: to-full-background 0.75s cubic-bezier(0.654, 0.045, 0.355, 1);
  background-color: var( --flex-gateway-highlight, #f9423a);
  border: 4px solid white;
}

@keyframes to-full-background {
  from {
    background-color: transparent;
    border: 4px solid transparent;
  }
  to {
    background-color: var( --flex-gateway-highlight, #f9423a);
    border: 4px solid white;
  }
}

html body .flex-gateway-layout .navigation .logoNavMob .logoHolderMatte1 {
  z-index: 1;
  position: absolute;
  background-color: black;
  /* background-color: #32325d; */
  mix-blend-mode: color-burn;
  overflow: hidden;
  display: none;
}

html body .flex-gateway-layout .navigation .logoNavMob .logoHolderMatte2 {
  position: absolute;
  /* background-color: white; */
  /* background-color: var(--flex-gateway-bg, #f6f3ea); */
  /* background-color: var(--flex-gateway-highlight, #f9423a); */
  /* mix-blend-mode: color-burn; */
  overflow: hidden;
  display: none;
}

html body .flex-gateway-layout .navigation .logoNavMob .logoHolder ~ .logoHolderMatte1,
html body .flex-gateway-layout .navigation .logoNavMob .logoHolder ~ .logoHolderMatte2,
html body .flex-gateway-layout .navigation .logoNavMob .logoBadge ~ .logoHolderMatte1,
html body .flex-gateway-layout .navigation .logoNavMob .logoBadge ~ .logoHolderMatte2 {
  display: block;
  animation-delay: 250ms;
  -webkit-animation: to-full-opacity 0.75s cubic-bezier(0.654, 0.045, 0.355, 1);
  animation: to-full-opacity 0.75s cubic-bezier(0.654, 0.045, 0.355, 1);
}

@keyframes to-full-opacity {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

html body .flex-gateway-layout .navigation .logoNavMob .logoHolderMatte1:before {
  content: "";
  position: absolute;
  z-index: 0;
  top: -5.5px;
  left: -42px;
  width: 0;
  height: 0;
  border-top: 33px solid transparent;
  border-bottom: 33px solid transparent;
  border-right: 113px solid white;
  /* border-right: 113px solid black; */
}

html body .flex-gateway-layout .navigation .logoNavMob .logoHolderMatte1:after {
  content: "";
  position: absolute;
  z-index: 0;
  top: -5.5px;
  right: -42px;
  width: 0;
  height: 0;
  border-top: 33px solid transparent;
  border-bottom: 33px solid transparent;
  border-left: 113px solid white;
  /* border-left: 113px solid black; */
}

html body .flex-gateway-layout .navigation .logoNavMob .logoHolderMatte2:before {
  content: "";
  position: absolute;
  z-index: 0;
  top: -5.5px;
  left: -42px;
  width: 0;
  height: 0;
  border-top: 33px solid transparent;
  border-bottom: 33px solid transparent;
  /* border-right: 113px solid black; */
  /* border-right: 113px solid white; */
  border-right: 113px solid var(--flex-gateway-highlight, #f9423a);
}

html body .flex-gateway-layout .navigation .logoNavMob .logoHolderMatte2:after {
  content: "";
  position: absolute;
  z-index: 0;
  top: -5.5px;
  right: -42px;
  width: 0;
  height: 0;
  border-top: 33px solid transparent;
  border-bottom: 33px solid transparent;
  /* border-left: 113px solid black; */
  /* border-left: 113px solid white; */
  border-left: 113px solid var(--flex-gateway-highlight, #f9423a);
}

/*

html body .flex-gateway-layout .navigation .logoNavMob .logoHolder .flex-gateway-logo.aside {
  position: relative;
  z-index: 1;
}

html body .flex-gateway-layout .navigation .logoNavMob .logoHolder #flexiness_logo_1 {
  position: absolute;
  width: 125px;
  height: auto;
  top: -4px;
  left: -4px;
} 
*/

html body .flex-gateway-layout .navigation .logoNavMob .logoBadge:before {
  z-index: 5;
  z-index: 1;
  position: absolute;
  content: "";
  width: 150px;
  height: 85px;
  top: -19px;
  left: -16px;
  /* background-image: url("/logo/transparent/rectangle/logo_flexiness_2.svg"); */
  /* background-image: url("/logo/transparent/rectangle/logo_flexiness_2_inverse.svg"); */
  background-image: url("/logo/filled/rectangle/logo_flexiness_2.svg");
  /* background-image: url("/logo/filled/rectangle/logo_flexiness_2_inverse.svg"); */
  background-size: 90%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}