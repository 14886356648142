/* --------------------------------------------------------*/
.flex-gateway-navigation header {
  background: var( --flex-gateway-bg, #f6f3ea);
  padding: 5em 5vw 2em;
}

.flex-gateway-navigation header h1 {
  color: var( --flex-gateway-header, #32325d);
  font-size: calc(10px + 2vw);
  margin: 0;
  line-height: 1.2em;
  text-transform: uppercase;
  font-weight: bold;
}

.flex-gateway-navigation header p {
  margin: 2em 0 0;
}

.flex-gateway-navigation header ul {
  margin: 0;
}

.flex-gateway-navigation header p,
.flex-gateway-navigation header a {
  color: var( --flex-gateway-text, #32325d);
}

.flex-gateway-navigation header a {
  border-bottom: none;
  text-transform: uppercase;
  font-weight: 700;
  text-decoration: none;
}

.flex-gateway-navigation header a:hover {
  color: var( --flex-gateway-highlight, #f9423a);
}

.flex-gateway-navigation .loader-navigation {
  position: absolute;
  width: 100px;
  height: 25px;
  top: calc(35vh - 12.5px);
  left: calc(50% - 50px);
  color: var( --flex-gateway-highlight, #f9423a);
}

@media (min-width: 1024px) {
  .flex-gateway-navigation .loader-navigation {
    top: calc(44vh - 12.5px);
    left: calc(50vw - 50px);
  }
}

@media (min-width: 1240px) {
  .flex-gateway-navigation .loader-navigation {
    top: calc(47vh - 12.5px);
    left: calc(50vw - 50px);
  }
}

.flex-gateway-demo .demo-list ul {
  list-style: none;
  padding: 0;
}

.flex-gateway-demo .demo-list h2,
.flex-gateway-demo .demo-list h2 span {
  font-size: clamp(1.1em, calc(3vw+0.8em), 1.2em);
  color: var( --flex-gateway-text, #32325d);
  margin: 0;
  line-height: 1.2;
  text-transform: uppercase;
  font-weight: bold;
  display: flex;
  flex-direction: column;
}

.flex-gateway-navigation header .follow-links {
  font-size: 0.9em;
  border-bottom: 25px solid var( --flex-gateway-highlight, #f9423a);
  line-height: 1.3;
  padding-bottom: 5px;
  font-weight: 400;
}

.flex-gateway-navigation header .workshop-link {
  border-top: 5px solid var( --flex-gateway-text, #32325d);
  border-bottom: 5px solid var( --flex-gateway-text, #32325d);
  font-weight: 700;
}

.flex-gateway-navigation header .workshop-link ul {
  list-style: none;
  padding: 0;
}

.flex-gateway-navigation .block-hide-when-aside {
  display: block;
}

.flex-gateway-navigation .inline-hide-when-aside {
  display: inline-block;
}

.flex-gateway-navigation .show-inline-when-aside {
  display: none;
}

.flex-gateway-navigation .show-block-when-aside {
  display: none;
}

@supports (display: grid) {
  .flex-gateway-navigation header {
    display: flex;
    margin: auto;
    padding-top: 8vh;
    padding-bottom: 12em;
  }

  .flex-gateway-navigation header .header-wrapper {
    width: 66vw;
    display: grid;
    margin: auto;
    grid-template-columns: auto 1fr 1fr 1fr 0.5fr;
    grid-template-rows: auto 1fr 1fr auto auto;
    grid-gap: 1em;
  }

  @media (min-width: 900px) {
    .flex-gateway-navigation header .header-wrapper {
      transform: rotate(-45deg);
    }
  }

  .flex-gateway-navigation header h1:nth-child(1) {
    grid-column: 1 / 4;
    text-align: right;
    display: flex;
    flex-direction: column;
  }

  .flex-gateway-navigation header h1:nth-child(2) {
    color: var( --flex-gateway-highlight, #f9423a);
    writing-mode: vertical-lr;
    grid-column: 3 / 4;
    grid-row: 2 / 5;
    justify-self: end;
    margin-top: -10px;
    margin-right: -4px;
    line-height: 0.8em;
  }

  .flex-gateway-navigation header p {
    margin: 0;
  }

  .flex-gateway-demo {
    grid-column: 1 / 3;
    grid-row: 4 / span 2;
    font-weight: 700;
    line-height: 1.2;
    align-self: end;
    min-width: 220px;
  }

  .flex-gateway-demo .demo-list li {
    border-top: 5px solid var( --flex-gateway-text, #32325d);
    text-align: left;
    padding: 2px 0;
  }

  .flex-gateway-demo .demo-list li:last-child {
    border-bottom: 5px solid var( --flex-gateway-text, #32325d);
  }

  .flex-gateway-demo .demo-list p {
    padding: 2px 0;
  }

  .flex-gateway-navigation header .follow-links {
    grid-column: 3 / 6;
    grid-row: 5;
    align-self: start;
    justify-content: end;
  }

  .flex-gateway-navigation header .workshop-link {
    grid-row: 3;
    grid-column: 4 / 6;
    align-self: end;
  }

  @keyframes to-aside-rotation {
    from {
      transform: rotate(-45deg);
    }
    to {
      transform: rotate(0);
    }
  }

  .animate-to-aside-rotation {
    -webkit-animation-name: to-aside-rotation;
    animation-name: to-aside-rotation;
  }

  @media (max-width: 820px) {
    .flex-gateway-navigation header {
      display: flex;
      margin: auto;
      padding-top: 5em;
      padding-bottom: 5em;
    }

    .flex-gateway-navigation.loading header .header-wrapper {
      width: 80vw;
    }

    .flex-gateway-navigation.aside header .header-wrapper {
      width: 100vw;
      transform: rotate(0);
    }

    .flex-gateway-navigation header h1 {
      font-size: calc(16px + 1.2vw);
    }

    .flex-gateway-navigation header h1:nth-child(2) {
      margin-top: -5px;
    }

    .flex-gateway-navigation header .workshop-link {
      margin-left: 1em;
      align-self: start;
    }

    .flex-gateway-navigation header .follow-links {
      border-bottom: 20px solid var( --flex-gateway-highlight, #f9423a);
    }
  }

  @media (max-width: 700px) {

    .flex-gateway-navigation.loading header .header-wrapper {
      width: 80vw;
    }

    .flex-gateway-navigation.aside header .header-wrapper {
      width: 100vw;
    }

    .flex-gateway-navigation header h1 {
      font-size: calc(16px + 1.2vw);
    }

    .flex-gateway-demo {
      grid-column: 3 / -1;
      grid-row: 6;
    }
  }

  @media (max-width: 500px) {
    .flex-gateway-navigation header {
      min-height: 100vh;
      padding: 0;
    }
    
    .flex-gateway-navigation.top header {
      min-height: auto;
    }
    

    .flex-gateway-navigation.loading header .header-wrapper {
      width: 300px;
    }

    .flex-gateway-navigation.aside header .header-wrapper {
      width: 100vw;
      grid-template-columns: auto 1fr 1fr 1fr 0.5fr;
      grid-template-rows: auto auto auto auto;
    }

    .flex-gateway-navigation header h1 {
      font-size: 40px;
      line-height: 1em;
    }

    .flex-gateway-navigation header h1:nth-child(1) {
      line-height: 1em;
      grid-column: 1 / -1;
    }

    .flex-gateway-navigation header h1:nth-child(2) {
      line-height: 0.8em;
      grid-column: 5;
      grid-row: 2 / 4;
      margin-top: -5px;
      margin-right: 0;
    }

    .flex-gateway-navigation header .follow-links {
      grid-column: 1 / 4;
      grid-row: 3;
      border-bottom: 10px solid var( --flex-gateway-highlight, #f9423a);
    }

    .flex-gateway-demo {
      grid-column: 1 / -1;
      grid-row: 4;
      min-width: auto;
    }

    .flex-gateway-navigation header .workshop-link {
      grid-column: 1 / -1;
      grid-row: 5;
      margin-left: 0;
    }

    .flex-gateway-navigation .block-hide-when-aside {
      display: none;
    }

    .flex-gateway-navigation .inline-hide-when-aside {
      display: none;
    }

    .flex-gateway-navigation .show-inline-when-aside {
      display: inline-block;
    }

    .flex-gateway-navigation .show-block-when-aside {
      display: block;
    }
  }

  /* --------------------------------------------------------*/

  .flex-gateway-navigation.aside {
    width: 100%;
  }

  .flex-gateway-navigation.aside,
  .flex-gateway-nav-menu.closed .flex-gateway-navigation.aside  {
    display: none;
  }

  .flex-gateway-nav-menu.open .flex-gateway-navigation.aside {
    display: block;
    margin-top: 1rem;
  }

  /* Moved to flex-gateway-globals.css */
  /* .flex-gateway-nav-menu.open .flex-gateway-navigation.aside header .header-wrapper {
    margin: 0 auto;
  } */

  @media (min-width: 900px) {
    .flex-gateway-navigation.aside {
      display: block !important;
    }
  }

  .flex-gateway-navigation.aside header {
    min-height: 100vh;
    padding: 0;
  }

  @media (min-width: 900px) {
    .flex-gateway-navigation.aside header {
      min-height: auto;
    }
  }

  .flex-gateway-navigation.aside header .header-wrapper {
    transform: rotate(0);
    grid-template-columns: auto 1fr 1fr 1fr 0.5fr;
    grid-template-rows: auto auto auto auto;
    -webkit-animation: none;
    animation: none;
  }

  @media (min-width: 900px) {
    .flex-gateway-navigation.aside header .header-wrapper {
      -webkit-animation: to-aside-rotation 0.5s cubic-bezier(0.654, 0.045, 0.355, 1);
      animation: to-aside-rotation 0.5s cubic-bezier(0.654, 0.045, 0.355, 1);
    }
  }

  .flex-gateway-navigation.aside header h1 {
    font-size: 40px;
    line-height: 1em;
  }

  .flex-gateway-navigation.aside header h1:nth-child(1) {
    line-height: 1em;
    grid-column: 1 / -1;
  }

  .flex-gateway-navigation.aside header h1:nth-child(2) {
    line-height: 0.8em;
    grid-column: 5;
    grid-row: 2 / 4;
    margin-top: -5px;
    margin-right: 0;
  }

  .flex-gateway-navigation.aside header .follow-links {
    grid-column: 1 / 4;
    grid-row: 3;
    border-bottom: 10px solid var( --flex-gateway-highlight, #f9423a);
  }

  .flex-gateway-demo.aside {
    grid-column: 1 / -1;
    grid-row: 4;
    min-width: auto;
  }

  .flex-gateway-navigation.aside header .workshop-link {
    grid-column: 1 / -1;
    grid-row: 5;
    margin-left: 0;
  }

  .flex-gateway-navigation.aside .block-hide-when-aside {
    display: none;
  }

  .flex-gateway-navigation.aside .inline-hide-when-aside {
    display: none;
  }

  .flex-gateway-navigation.aside .show-inline-when-aside {
    display: inline-block;
  }

  .flex-gateway-navigation.aside .show-block-when-aside {
    display: block;
  }

  /* --------------------------------------------------------*/
  @media (min-width: 1000px) {
    .flex-gateway-navigation header {
      padding-bottom: 15em;
    }
  }

  @media (min-width: 1300px) {
    .flex-gateway-navigation header {
      padding-bottom: 25em;
    }
  }

  @media (min-width: 1800px) {
    .flex-gateway-navigation header {
      padding-bottom: 30em;
    }
  }
}